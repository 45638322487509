import React from "react";
import { Button, Container, Form, Grid, Header, Input, Segment, TextArea } from "semantic-ui-react";
import { antiquaFont } from "../utils/constants";

const VContactUs = () => {
	const handleSubmit = () => {
        
		var number = "+917989475162"
		let url = `https://wa.me/${number}`;

		var message = "Hello World"
		// Appending the message to the URL by encoding it
		url += `?text=${encodeURI(message)}`;

		// Open our newly created URL in a new tab to send the message
		window.open(url);

    }
	return (
		<Grid textAlign='center' verticalAlign='middle'>
			<Grid.Column style={{ maxWidth: 450 }} width={16}>
				<Form size='large'>
					<Segment vertical>
						<Form.Input fluid icon='user' iconPosition='left' placeholder='Name' />
						<Form.Input fluid icon='mail' iconPosition='left' placeholder='Email' />
						<Form.Input fluid icon='mobile' iconPosition='left' placeholder='Mobile' />
						<Form.TextArea placeholder='Tell us more' style={{ minHeight: 100 }} />
						<Button style={Object.assign({backgroundColor:"#7097B4",color:"white"},antiquaFont)} fluid size='large' onClick={handleSubmit}>
							Send
						</Button>
					</Segment>
				</Form>
			</Grid.Column>
		</Grid>
	)
}
export default VContactUs;