const SLIDER_IMAGES = [
    {
        src:"../resources/images/slide1.jpg",
        title: "Robust & High Quality Products from Leading Brands.",
        description: ""
    },
    {
        src:"../resources/images/slide2.jpg",
        title: "Modern & Cost Effective Solutions",
        description: ""
    },
    {
        src:"../resources/images/slide3.jpg",
        title: "Dedicated Customer Service",
        description: ""
    }
  ];
  
  export default SLIDER_IMAGES;
  