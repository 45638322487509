import React, { useEffect, useState } from "react";
import { Button, Container, Dropdown, Form, Grid, Icon, Image, ImageGroup, Segment } from "semantic-ui-react";
import { fullHeight, headerBackground, headerContactText, headerDropdownText, headerStyling, logoColumnProps, noPadding, textFontArimo,textFontOpenSans } from "../utils/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const VHeader = () => {
    const calculateLogoWidth = () => {
        var logoWidth = 4
        
        if(window.innerWidth > 768) {
            logoWidth = 4
        }else {
            logoWidth = 8
        }
        return logoWidth
    }

    const [logoWidth, setLogoWidth] = useState(calculateLogoWidth);

    useEffect(() => {
        const handleWindowResize = () => {
            setLogoWidth(calculateLogoWidth)
        }
        window.addEventListener('resize', handleWindowResize);
    
        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
    });
    
    const handleInstagramClick = () => {
        window.open('https://www.instagram.com/vesara_hardware/');
    }
    return (
        <Segment vertical textAlign='center' style={{ backgroundColor:"#FFFFFF" }}>
        <Grid verticalAlign="middle" >
            <Grid.Row style={noPadding}>
                <Grid.Column width={1}>
                </Grid.Column>
                <Grid.Column width={logoWidth}>
                    <Image src={require('../resources/images/vlogo.png')} style={{width:"300px"}}/>
                    
                </Grid.Column>
                <Grid.Column width={11} className="hide_on_mobile">
                    <HeaderContact handleInstagramClick={handleInstagramClick}/>
                </Grid.Column>
                <Grid.Column width={7} only="mobile" textAlign="center">
                    <Form className='ui form massive'>
                    <Dropdown icon="bars" direction='left'>
                        <Dropdown.Menu>
                            <Dropdown.Item style={headerDropdownText} icon='mail outline' text='Email: info@vesarahardware.com' />
                            <Dropdown.Item style={headerDropdownText} icon='phone' text='Phone: +917989475162' />
                            <Dropdown.Item style={headerDropdownText} icon='instagram' text='vesara_hardware' onClick={handleInstagramClick}/>
                        </Dropdown.Menu>
                    </Dropdown>
                    </Form>
                </Grid.Column>
            </Grid.Row>
        </Grid>
        </Segment>
    )
}

export const HeaderContact = (props) => {
    const handleInstagramClick = props.handleInstagramClick;

    return(
        <Segment vertical textAlign='center' style={{ backgroundColor:"white",minHeight:"60px"}}>
        <Grid verticalAlign="middle">
            <Grid.Column width={8} textAlign="right">
                <span style={headerContactText}>
                    <Icon name="mail outline"/>Email: info@vesarahardware.com
                </span>
            </Grid.Column>
            <Grid.Column width={5} textAlign="center">
                <span style={headerContactText}>
                    <Icon name="phone"/>Phone: +917989475162
                </span>
            </Grid.Column>
            <Grid.Column width={3}>
                <span style={Object.assign({cursor:"pointer"},headerContactText)} onClick={handleInstagramClick}>
                    <Icon name="instagram" link/>vesara_hardware
                </span>
            </Grid.Column>
        </Grid>
        </Segment>
    )
}
export default VHeader;