import React from "react";
import { Grid } from "semantic-ui-react";
import {fullHeight, fullWidth, mainLayoutStyling, noPadding} from "../utils/constants"
import MainLayout from "./MainLayout";

const AppLayout = () => {
    return(
        <Grid style={mainLayoutStyling}>
            <Grid.Row columns={1} style={noPadding}>
                <Grid.Column width={16} style={noPadding}>
                    <MainLayout />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}
export default AppLayout;