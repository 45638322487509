import React from "react";
import { Grid, Segment } from "semantic-ui-react";
import VHeader, { HeaderContact } from "../components/VHeader";
import VBody from "../components/VBody";
import VFooter from "../components/VFooter";
import { noPadding, rowGap } from "../utils/constants";

const MainLayout = () => {
    return(
        <Segment vertical>
        <Grid stackable verticalAlign="middle">
            <Grid.Row>
                <Grid.Column width={16} className="no_padding">
                    <VHeader />
                </Grid.Column>
                <Grid.Column width={16} style={noPadding}>
                    <VBody />
                </Grid.Column>
                <Grid.Column width={16} style={rowGap}>
                    <VFooter />
                </Grid.Column> 
            </Grid.Row>
        </Grid>
        </Segment>
    )
}

export default MainLayout;