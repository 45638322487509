export const fullHeight = {
    height: "100%"
}

export const fullWidth = {
    width: "100%"
}

export const noPadding = {
    padding: "0px"
}

export const noMargin = {
    margin: "0px"
}

export const mainLayoutStyling = {
    minHeight:"600px",
}

export const headerStyling = {
    minHeight:"115px",
}

export const headerBackground = {
    backgroundColor:"#231F20"
}

export const antiquaFont = {
    fontFamily:"antiqua"
}

export const headerContactText = {
    fontSize:"19px",
    color:"black",
    ...antiquaFont
}

export const headerDropdownText = {
    fontSize:"15px",
    color:"black",
    ...antiquaFont
}

export const headingStyle = {
    fontSize: '3em',
    fontFamily:"cambria"
}

export const logoColumnProps = {
    minHeight: "100px",
    minWidth: "158px"
}

export const textFontArimo = {
    fontFamily: "Arimo"
}

export const textFontOpenSans = {
    fontFamily: "Open Sans"
}

export const rowGap = {
    marginTop:"1em",
    marginBottom:"1em"
}