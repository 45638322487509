import React from "react";
import { Container, Grid, Header, List, Segment } from "semantic-ui-react";
import { antiquaFont } from "../utils/constants";

const VFooter = () => {
    return (
        <Segment style={{padding: '5em 0em',backgroundColor:"#2F2E2E"}} vertical>
            <Container>
                <Grid divided inverted stackable>
                    <Grid.Row>
                        <Grid.Column width={3}>
                            <Header inverted as='h4' content='Quick Links' style={antiquaFont}/>
                            <List link inverted style={antiquaFont}>
                                <List.Item as='a'>About</List.Item>
                                <List.Item as='a'>Services</List.Item>
                                <List.Item as='a'>Blog</List.Item>
                                <List.Item as='a'>Contact Us</List.Item>
                            </List>
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <Header inverted as='h4' content='Services' style={antiquaFont}/>
                            <List link inverted style={antiquaFont}>
                                <List.Item as='a'>Modular Kitchens & Sliding Wardrobes</List.Item>
                                <List.Item as='a'>Partition Systems for Home & Office</List.Item>
                                <List.Item as='a'>Bespoke Shower Enclosures</List.Item>
                                <List.Item as='a'>Door & Furniture Hardware</List.Item>
                            </List>
                        </Grid.Column>
                        <Grid.Column width={7}>
                            <Header as='h4' inverted style={antiquaFont}>
                                We're Located at
                            </Header>
                            <p style={Object.assign({color:"white"},antiquaFont)}>
                                SY NO.192-24, DOMMASANDRA SARJAPURA MAIN ROAD ANEKAL, Bengaluru, Karnataka 562125
                            </p>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        </Segment>
    )
}
export default VFooter;