import React,{ useEffect, useState } from "react";
import Carousel from 'react-bootstrap/Carousel';
import { 
    Button, Card, CardGroup, 
    Container, Divider, Grid, 
    Header, Icon, Image, 
    Segment
} from "semantic-ui-react";
import VContactUs from "./VContactUs";
import { antiquaFont, carouselImageStyling, headingStyle, noPadding, rowGap } from "../utils/constants";
import SLIDER_IMAGES from "../utils/sliderImages";
import { MapContainer, TileLayer, useMap,Popup,Marker } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
});

L.Marker.prototype.options.icon = DefaultIcon;

const VBody = () => {
    const [index, setIndex] = useState(0);

    const calculateTabCount = () => {
        var tabCount = 1
        
        if(window.innerWidth > 1150) {
            tabCount = 3
        } else if(window.innerWidth > 700 && window.innerWidth < 1150){
            tabCount = 2
        } else {
            tabCount = 1
        }
        return tabCount
    }

    const renderFluidImage = () => {
        var fluidImage = true
        if(window.innerWidth > 1150) {
            fluidImage = false
        } else if(window.innerWidth > 700 && window.innerWidth < 1150){
            fluidImage = false
        } else {
            fluidImage = true
        }
        return fluidImage
    }

    const [tabCount, setTabCount] = useState(calculateTabCount);
    const [fluid, setFluid] = useState(renderFluidImage);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    const handleWhatsappClick = () => {
        window.open('https://wa.me/+917989475162');
    }

    useEffect(() => {
        const handleWindowResize = () => {
            setTabCount(calculateTabCount)
            setFluid(renderFluidImage)
        }
        window.addEventListener('resize', handleWindowResize);
    
        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
    });
    return (
        <Segment vertical style={{paddingTop:"0px"}}>
        <Grid stackable verticalAlign='middle'>
            <Grid.Row>
                <Grid.Column width={16} style={noPadding}>
                <div className='af-height-90 af-max-width mx-auto'>
                    <Carousel>
                        <Carousel.Item>
                            <img
                                className='d-block w-100'
                                src={require("../resources/images/slide1.jpg")}
                                alt='First slide'
                            />
                            <Carousel.Caption className='position-absolute'>
                            <div className='af-position-lg af-bg-dark-transparent py-3'>
                                <h3 style={antiquaFont}>{SLIDER_IMAGES[0].title}</h3>
                                <p style={antiquaFont}>{SLIDER_IMAGES[0].description}</p>
                            </div>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                            className='d-block w-100'
                            src={require("../resources/images/slide2.jpg")}
                            alt='Third slide'
                            />
                            <Carousel.Caption className='position-absolute'>
                            <div className='af-position-lg af-bg-dark-transparent py-3'>
                                <h3 style={antiquaFont}>{SLIDER_IMAGES[1].title}</h3>
                                <p style={antiquaFont}>{SLIDER_IMAGES[1].description}</p>
                            </div>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                            className='d-block w-100'
                            src={require("../resources/images/slide3.jpg")}
                            alt='Third slide'
                            />
                            <Carousel.Caption className='position-absolute'>
                            <div className='af-position-lg af-bg-dark-transparent py-3'>
                                <h3 style={antiquaFont}>{SLIDER_IMAGES[2].title}</h3>
                                <p style={antiquaFont}>{SLIDER_IMAGES[2].description}</p>
                            </div>
                            </Carousel.Caption>
                        </Carousel.Item>
                    </Carousel>
                </div>
                </Grid.Column>
            </Grid.Row>
            <Divider />
            <Grid.Row style={rowGap}>
                <Grid container stackable verticalAlign='middle'>
                <Grid.Column width={8} style={{textAlign:"justify"}}>
                    <Container style={{paddingLeft:"35px"}}>
                        <Header as='h3' style={headingStyle}>
                            About VESARA
                        </Header>
                        <p style={Object.assign({fontSize: '1.2em'},antiquaFont)}>
                        A family enterprise with over 30+ years of experience in the interior hardware solutions space.
                        Vesara hardware is the newest offering in Karnataka for both Wholesale Business & Retail Customers. It's newly opened store is spread over 5000sqft & provides a lavish experience for it's clientele.
                        </p>
                    </Container>
                </Grid.Column>
                <Grid.Column width={8}>
                    <Image src={require('../resources/images/wardrobe2.png')} />
                </Grid.Column>
                </Grid>
            </Grid.Row>
            <Divider />
            <Grid.Row style={rowGap}>
                <Header as='h3' style={headingStyle}>
                    How We Differ
                </Header>
                <Grid>
                <Grid.Column width={2}></Grid.Column>
                <Grid.Column width={13}>
                    <p style={Object.assign({fontSize: '1.2em'},antiquaFont)}>
                    The young, dedicated & dynamic team at Vesara Hardware makes sure the customer gets an engaging & lively experience. 
                    Our customer centric model ensures complete piece of mind to the customer through its maintenance and installation services.
                    </p>
                </Grid.Column>
                <Grid.Column width={1}></Grid.Column>
                </Grid>
            </Grid.Row>
            <Divider />
            <Grid.Row style={Object.assign({minHeight:"500px",backgroundColor:"#F2F2F2"},rowGap)} className="service_cards">
                <Header as='h3' style={headingStyle}>
                    Our Services
                </Header>
                <Grid style={{backgroundColor:"#F2F2F2",marginTop:"1.5em",marginBottom:"1.5em"}}>
                <Grid.Column width={2}></Grid.Column>
                <Grid.Column width={13}>
                <Card.Group itemsPerRow={tabCount}>
                    <Card raised link>
                        <Image src={require('../resources/images/mk1.png')} fluid={fluid} style={{height:"335px"}}/>
                        <Card.Content>
                        <Card.Header style={antiquaFont}>Modular Kitchen</Card.Header>
                        <Card.Description style={antiquaFont}>
                            View More
                        </Card.Description>
                        </Card.Content>
                    </Card>
                    <Card raised link>
                        <Image src={require('../resources/images/sw1.png')} fluid={fluid} style={{height:"335px"}}/>
                        <Card.Content>
                        <Card.Header style={antiquaFont}>Sliding Wardrobes</Card.Header>
                        <Card.Description style={antiquaFont}>
                            View More
                        </Card.Description>
                        </Card.Content>
                    </Card>
                    <Card raised link>
                        <Image src={require('../resources/images/ps1.png')} fluid={fluid} style={{height:"335px"}}/>
                        <Card.Content>
                        <Card.Header style={antiquaFont}>Partition Systems for Home & Office</Card.Header>
                        <Card.Description style={antiquaFont}>
                            View More
                        </Card.Description>
                        </Card.Content>
                    </Card>
                    <Card raised link>
                        <Image src={require('../resources/images/se1.png')} fluid={fluid} style={{height:"335px"}}/>
                        <Card.Content>
                        <Card.Header style={antiquaFont}>Bespoke Shower Enclosures</Card.Header>
                        <Card.Description style={antiquaFont}>
                            View More
                        </Card.Description>
                        </Card.Content>
                    </Card>
                    <Card raised link>
                        <Image src={require('../resources/images/dh1.png')} fluid={fluid} style={{height:"335px"}}/>
                        <Card.Content>
                        <Card.Header style={antiquaFont}>Door & Furniture Hardware</Card.Header>
                        <Card.Description style={antiquaFont}>
                            View More
                        </Card.Description>
                        </Card.Content>
                    </Card>
                    
                </Card.Group>
                </Grid.Column>
                <Grid.Column width={1}></Grid.Column>
                </Grid>
            </Grid.Row>
            {/* <Grid.Row style={rowGap}>
                <Header as='h3' style={Object.assign({fontSize: '2em'},antiquaFont)}>
                    Connect With Us!
                </Header>
                <Grid container stackable verticalAlign='middle' style={{paddingTop:"1.5em"}}>
                <Grid.Column width={8} style={{textAlign:"justify"}}>
                    <VLocationComponent />
                </Grid.Column>
                <Grid.Column width={8}>
                    <VContactUs />
                </Grid.Column>
                </Grid>
            </Grid.Row> */}
            
            <Icon size="huge" name='whatsapp' style={{paddingTop:"3px",paddingRight:"30px"}} 
                    link className="whatsapp_float" onClick={handleWhatsappClick}
            />
        </Grid>
        </Segment>
    )
}

const VLocationComponent = () => {

    return (
        <Container style={{height:500,width:"500px"}}>
            <MapContainer style={{
				height: "100%",
				width: "100%",
			}} center={[12.894470, 77.767800]} zoom={13} scrollWheelZoom={false}>
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
            <Marker position={[12.894470, 77.767800]}>
                <Popup>
                    <span style={antiquaFont}>We're Here</span>
                </Popup>
            </Marker>
            </MapContainer>
        </Container>
    )
}
export default VBody;